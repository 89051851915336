import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { Button, Col, CustomProvider, Grid, IconButton, Row } from "rsuite"
import { getUserLetterhead, setBreadcrumbItems } from "../../../store/actions"
import MoreIcon from "@rsuite/icons/More"
import CloseOutlineIcon from "@rsuite/icons/CloseOutline"
import NewDraftTable from "./NewDraftTable"
import "../bulkoption.scss"
import BulkMenu from "../BulkMenu"
import { useHistory, useParams } from "react-router-dom"
import { ShowSwal, ShowSwalForMultiAction } from "common/ShowSwal"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ShowNotification } from "common/ShowNotification"
import DrwerBar from "components/Common/DrwerBar"
import { RouteForShow } from "common/ApiRoute"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import FilterMenu from "../FilterMenu"

const UserDraft = props => {
  const [checkedKeys, setCheckedKeys] = useState([])
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const params = useParams()
  const [action, setAction] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [openUploadSignedPdfDrawer, setOpenUploadSignedPdfDrawer] =
    useState(false)
  const [openUploadBulkDataDrawer, setOpenUploadBulkDataDrawer] =
    useState(false)
  const [openNoticeDraftDrawer, setOpenNoticeDraftDrawer] = useState(false)
  const [openSendNoticeDrawer, setOpenSendNoticeDrawer] = useState(false)
  const [downloadAs, setDownloadAs] = useState("notice_id")
  const [fromTo, setFromTo] = useState({
    rangeFrom: "",
    rangeTo: "",
    rangeExcept: "",
  })
  const [filter, setFilter] = useState({
    batch: "",
    case_ref_id: "",
    submit: false,
    filterFrom: "",
    filterTo: "",
  })
  const [scheduleNotice, setScheduleNotice] = useState(new Date().toString())
  const [signedPDFs, setSignedPDFs] = useState([])
  const [bulkData, setBulkData] = useState({
    token: props?.token,
    noticeId: params?.id,
    batchName: null,
    user_bulk_data: null,
    lhead: null,
    is_sig: 1,
  })
  const [bulkDataError, setBulkDataError] = useState({
    batchName: null,
    user_bulk_data: null,
    lhead: null,
  })

  const breadcrumbItems = params?.userId
    ? [
        { title: "Incase360", link: "#" },
        { title: "Sub User", link: "/user/subuser" },
        {
          title: "Sub User Notice List",
          link: "/user/subuser/noticelist/" + params?.userId,
        },
        { title: "Sub User Scheduled Notice Details", link: "#" },
      ]
    : [
        { title: "Incase360", link: "#" },
        { title: "Notice List", link: "/user/noticelist" },
        { title: "Scheduled Notice Details", link: "#" },
      ]

  const handleUploadSignedPdfOpenDrawer = () => {
    setOpenUploadSignedPdfDrawer(true)
  }

  const handleUploadBulkDataOpenDrawer = () => {
    setOpenUploadBulkDataDrawer(true)
  }

  const handleBulkRejectNotice = () => {
    const request = {
      pdfs: checkedKeys,
      token: props?.token,
      mnid: params?.id,
      ...fromTo,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Reject it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/reject-bulk-notice", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              history.push("/user/rejected-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
    // console.log(request);
  }

  const handleOpenSendNoticeDrawer = () => {
    setOpenSendNoticeDrawer(true)
  }

  const handleBulkSendNotice = () => {
    const request = {
      pdfall_ids: checkedKeys,
      token: props?.token,
      mnid: params?.id,
      schedule_on: scheduleNotice,
      ...fromTo,
    }
    ShowSwal(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Send it!",
      result => {
        if (result.isConfirmed) {
          ApiCall(request, "user/send-bulk-notice", false, result => {
            if (result?.data?.response === "success") {
              setAction(!action)
              ShowNotification(result?.data?.message, "success")
              // history.push("/user/active-notice/" + params.id)
            } else {
              ShowNotification(result?.data?.message, "danger")
            }
          })

          // Swal.fire("Deleted!", "Your file has been deleted.", "success")
          // ShowNotification("Deleted! Your file has been deleted.", "success")
        }
      }
    )
    // console.log(request);
  }

  const handleUploadSignedPDFsAction = () => {
    const request = {
      token: props?.token,
      noticeId: params?.id,
      user_bulk_sign_data: signedPDFs,
      totalFiles: signedPDFs?.length,
    }
    signedPDFs?.length !== 0 &&
      ApiCall(request, "user/signed-pdf-upload", true, result => {
        if (result?.data?.response === "success") {
          setOpenUploadSignedPdfDrawer(false)
          ShowNotification(result?.data?.message, "success")
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  const handleClose = () => {
    openUploadBulkDataDrawer && [
      setOpenUploadBulkDataDrawer(false),
      setBulkData({
        ...bulkData,
        batchName: null,
        user_bulk_data: null,
        lhead: null,
        is_sig: 1,
      }),
    ]
    openUploadSignedPdfDrawer && [
      setOpenUploadSignedPdfDrawer(false),
      setSignedPDFs([]),
    ]
    openSendNoticeDrawer && [
      setOpenSendNoticeDrawer(false),
      setScheduleNotice(""),
    ]
    openNoticeDraftDrawer && setOpenNoticeDraftDrawer(false)
  }

  const handleUploadBulkDataAction = () => {
    // console.log(bulkData)
    bulkData?.batchName &&
      bulkData?.lhead &&
      bulkData?.user_bulk_data &&
      bulkData?.is_sig &&
      ApiCall(bulkData, "user/upload-bulk-csv", false, result => {
        if (result?.data?.response === "success") {
          // setOpenUploadSignedPdfDrawer(false)
          setOpenUploadBulkDataDrawer(false)
          // ShowNotification(result?.data?.message, "success")
          ShowSwalForMultiAction(
            result?.data?.message,
            "Please wait for sometime while cases are being generate.",
            "success",
            "CSV Report",
            "Ok",
            result => {
              if (result.isConfirmed) {
                history.push("/user/report")
              }
            }
          )
        } else {
          ShowNotification(result?.data?.message, "danger")
        }
      })
  }

  useEffect(() => {
    setBulkDataError({
      ...bulkDataError,
      batchName: !bulkData?.batchName ? "Required Field" : null,
      lhead: !bulkData?.lhead ? "Required Field" : null,
      user_bulk_data: !bulkData?.user_bulk_data
        ? "Please Select at list one file"
        : null,
    })
  }, [bulkData])

  const handleBulkDownloadNotices = () => {
    // if()
    const request = {
      token: props?.token,
      all_ids: checkedKeys,
      mnid: params?.id,
      download_as: downloadAs,
      ...fromTo,
    }
    // ApiCall(request, "download/download-notice", false, result => {
    //   console.log(result);
    // })
    DownloadApiCall(request, "download/download-notice", "_self")
  }

  const handleopenNoticeDraftDrawer = () => {
    setOpenNoticeDraftDrawer(true)
  }

  const fromToDate = [
    {
      id: 1,
      name: "rangeDateFrom",
      placeholder: "From",
      onChange: e => setFilter({ ...filter, filterFrom: e }),
    },
    {
      id: 2,
      name: "rangeDateTo",
      placeholder: "To",
      min: filter?.filterFrom,
      onChange: e => setFilter({ ...filter, filterTo: e }),
    },
  ]

  const bulkButton = [
    {
      id: 1,
      size: "xs",
      color: "blue",
      label: "View Draft Notice",
      onClick: handleopenNoticeDraftDrawer,
    },
    {
      id: 2,
      size: "xs",
      color: "cyan",
      label: "Download CSV Format",
      // display: checkedKeys.length === 0 && "d-none",
      // onClick: handleApproveOpenDrawer,
      href: RouteForShow + "notice_bulk/" + props?.data?.notice_bulk,
    },
    {
      id: 3,
      size: "xs",
      color: "orange",
      label: "Bulk Upload Data",
      // display: checkedKeys.length === 0 && "d-none",
      onClick: handleUploadBulkDataOpenDrawer,
    },
    {
      id: 4,
      size: "xs",
      color: "violet",
      label: "Upload Signed PDFs",
      // display: checkedKeys.length === 0 && "d-none",
      onClick: handleUploadSignedPdfOpenDrawer,
    },
    {
      id: 5,
      size: "xs",
      color: "cyan",
      label: "Bulk Download Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "")
          ? "d-block"
          : "d-none",
      onClick: handleBulkDownloadNotices,
    },
    {
      id: 6,
      size: "xs",
      color: "green",
      label: "Send Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "")
          ? "d-block"
          : "d-none",
      onClick: handleOpenSendNoticeDrawer,
    },
    {
      id: 7,
      size: "xs",
      color: "red",
      label: "Reject Notices",
      display:
        checkedKeys.length > 0 ||
        (fromTo?.rangeFrom !== "" && fromTo?.rangeTo !== "")
          ? "d-block"
          : "d-none",
      onClick: handleBulkRejectNotice,
    },
    // {
    //   id: 8,
    //   size: "xs",
    //   color: "cyan",
    //   label: "Download Notice Links",
    //   display: checkedKeys.length === 0 && "d-none",
    //   // onClick: handleApproveOpenDrawer,
    // },
  ]

  const fromToInput = [
    {
      id: 1,
      name: "rangeFrom",
      placeholder: "From ex. IN12-1234",
      onChange: e => setFromTo({ ...fromTo, rangeFrom: e }),
    },
    {
      id: 2,
      name: "rangeTo",
      placeholder: "To ex. IN12-2345",
      onChange: e => setFromTo({ ...fromTo, rangeTo: e }),
    },
    {
      id: 3,
      name: "rangeExcept",
      placeholder: "Except ex. IN12-23,IN12-34,IN12-45",
      onChange: e => setFromTo({ ...fromTo, rangeExcept: e }),
    },
  ]

  useEffect(() => {
    props.setBreadcrumbItems(
      `${
        params?.userId ? "Sub User" : ""
      } Scheduled Notice Details - ${props?.data?.noticeName?.replace(
        "notice_",
        ""
      )}`,
      breadcrumbItems
    )
  })

  // console.log(props?.data?.notice_bulk)

  useEffect(() => {
    dispatch(
      getUserLetterhead({
        token: props?.token,
        iDisplayLength: null,
        iDisplayStart: null,
      })
    )
  }, [])

  const approveLetter = props?.UserLetterhead?.filter(value => {
    return parseInt(value?.status) === 1
  })

  // console.log(props?.data);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Notice Details - User | Incase360</title>
      </MetaTags>
      <CustomProvider theme="dark">
        {openNoticeDraftDrawer && (
          <DrwerBar
            open={openNoticeDraftDrawer}
            setOpen={setOpenNoticeDraftDrawer}
            title="Draft Notice"
            body="view"
            size="sm"
            view={true}
            subDataFromHtml={props?.data?.draft_notice_html}
            onCancel={handleClose}
          />
        )}
        {openSendNoticeDrawer && (
          <DrwerBar
            open={openSendNoticeDrawer}
            setOpen={setOpenSendNoticeDrawer}
            title="Send Notice"
            size="xs"
            setState={setScheduleNotice}
            body="SendNotice"
            onClick={handleBulkSendNotice}
            onCancel={handleClose}
          />
        )}
        {openUploadSignedPdfDrawer && (
          <DrwerBar
            open={openUploadSignedPdfDrawer}
            setOpen={setOpenUploadSignedPdfDrawer}
            title="Upload Digital Signed PDFs"
            body="UploadSigned"
            size="xs"
            onClick={() => handleUploadSignedPDFsAction()}
            state={signedPDFs}
            setState={setSignedPDFs}
            onCancel={handleClose}
          />
        )}
        {openUploadBulkDataDrawer && (
          <DrwerBar
            open={openUploadBulkDataDrawer}
            setOpen={setOpenUploadBulkDataDrawer}
            title="Upload Bulk Data"
            body="UploadBulkData"
            subData={approveLetter}
            size="xs"
            onClick={() => handleUploadBulkDataAction()}
            state={bulkData}
            setState={setBulkData}
            error={bulkDataError}
            setError={setBulkDataError}
            onCancel={handleClose}
          />
        )}
          <FilterMenu
            openFilterMenu={openFilterMenu}
            setOpenFilterMenu={setOpenFilterMenu}
            state={filter}
            setState={setFilter}
            option={true}
            mnid={params?.id}
            tab="draft"
            fromToDate={fromToDate}
          />
        <NewDraftTable
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
          setOpenSubMenu={setOpenSubMenu}
          action={action}
          filter={filter}
        />
      </CustomProvider>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.DraftNotice.items,
  token: state.Login.token,
  UserLetterhead: state.UserLetterhead.items,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(UserDraft)
