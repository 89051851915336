import MetaTags from "react-meta-tags"
import React, { useRef, useState } from 'react'
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
// Redux
import { withRouter, Link, useHistory, Redirect } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect, useDispatch } from "react-redux"
import axios from "axios"
import { logIn } from "store/actions"
import { ApiRoute } from "common/ApiRoute"
import { ShowNotification } from "common/ShowNotification"
import Captch from "./Captch"
import LogonbannerModle from "./LogonbannerModle"

// actions

// import images
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoDark from "../../assets/images/logo-dark.png";

  const Login = props => {
  const dispatch = useDispatch()
   const [captchaDetail,setCaptchaDetail] = useState({})
    const [showBanner,setShowBanner] = useState(false)
  const api_url = ApiRoute
  const history = useHistory()
     const [formData,setFormdata] = useState({})
const getValue = (answer,result) => {
      setCaptchaDetail({...captchaDetail,answer:answer,result:result})
}
  
const handleCloseBanner= () => {
   setShowBanner(false)
}

    const handleLoginApi = () => {
      axios.post(api_url + "userLogin", formData).then(response =>
        response.data.code === 200
          ? (dispatch(logIn(response)),
            setTimeout(() => {
              ShowNotification("Welcome......", "success")
              // navigate("/user/dashboard", { replace: true })
              history.push("/access")
            }, 500))
          : response.data.code === 401
          ? ShowNotification("Under Admin Review...", "info")
          : ShowNotification(response.data.message, "danger")
      )
    }
 // handleValidSubmit
  const handleValidSubmit = (event, values) => {
        if (captchaDetail?.answer === captchaDetail?.result) {
            setShowBanner(true)
             setFormdata(values)
        }
        else {
      ShowNotification("Invalid Captcha", "danger")
    }
  }

  return (
    <React.Fragment>
      {parseInt(props?.data?.role) === 0 ? (
        <Redirect to="/user/dashboard" />
      ) : parseInt(props?.data?.role) === 1 ? (
        <Redirect to="/admin/dashboard" />
      ) : (
        <>
          <MetaTags>
            <title>Login | Incase360</title>
          </MetaTags>
          <div className="account-pages my-2 pt-sm-4">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <h3 className="text-center mt-4 mb-3">
                        <Link to="/" className="d-block auth-logo">
                          <img
                            src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                            alt=""
                            height="30"
                            className="auth-logo-dark"
                          />
                          <img
                            src="https://api.incase360.com/assets/images/Incase360-logo-1-white.png"
                            alt=""
                            height="30"
                            className="auth-logo-light"
                          />
                        </Link>
                      </h3>
                      <LogonbannerModle isOpen={showBanner} handleClose={handleCloseBanner} handleAccept ={handleLoginApi}/>
                      <div className="p-3">
                        <h4 className="font-size-24 text-center" style={{color:'#ffff'}}>
                          Welcome Back !
                        </h4>
                        <p className="text-center">
                          Sign in to continue to Incase360.
                        </p>
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >
                          {/* {props.error && typeof props.error === "string" ? (
												<Alert color="danger">{props.error}</Alert>
											) : null} */}

                          <div className="mb-3">
                            <AvField
                              name="userName"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="userPassword"
                              label="Password"
                              type="password"
                              required
                              placeholder="Enter Password"
                            />
                          </div>
                            <Captch getValue={getValue} />
                          <div className="mb-3 row mt-2">
                            <div className="col-6">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            
                            <div className="col-6 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                          <div className="form-group mb-0 row">
                            <div className="col-12 text-center mt-3">
                              <Link
                                to="/forgot-password"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                            <div className="col-12 text-center mt-2">
                              <p>
                                Don&#39;t have an account ?{" "}
                                <Link to="/register" className="text-primary">
                                  {" "}
                                  Signup now{" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.Login,
})

export default withRouter(connect(mapStateToProps)(Login))

// Login.propTypes = {
// 	error: PropTypes.any,
// 	history: PropTypes.object,
// 	loginUser: PropTypes.func,
// };
