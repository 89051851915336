import { UserActionService } from "Service/userActionService";
import axios from "axios";
import { ApiRoute } from "common/ApiRoute";
import { ShowNotification } from "common/ShowNotification";
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Button, Col, Input, Label, Row } from "reactstrap"
import { DatePicker, Modal} from 'rsuite';
import { formatDate } from "utils/formatDate";

export default function BulkSendModal({open,setOpen,bulksendIds,noticeDetails,filter,reset}) {
   const date = new Date()
 const [bulkUploadData,setbulkUploadData] = useState({
  batchName:"",
  schedule_on:date.toString()
 })
  const userData = useSelector((state)=>state.Login)
    const handleSubmit = () =>{
        setOpen(false)
      }

      const bulksendApi = (payload) =>  {
        const request = new FormData()
         request.append('pdfall_ids',payload.pdfall_ids)
         request.append('token',userData?.token)
         request.append('mnid',payload.mnid)
       payload.schedule_on && request.append('schedule_on',payload.schedule_on)
         request.append('rangeFrom',payload.rangeFrom)
         request.append('rangeTo',payload.rangeTo)
         request.append('rangeExcept',payload.rangeExcept)
         axios.post(`${ApiRoute}user/send-bulk-notice`,request)
         .then((res)=>{
             if(res.data.code === 200) {

               const payload1 = new FormData()
               Object.entries(payload).forEach(([key, value]) => {
                payload1.append( key, value );
               });
              axios.post(`${ApiRoute}bulk-send-apiNotice`,payload1)
              .then((response)=>{
                 if(response.data.code === 200) {
                        ShowNotification('Send Successfull!','success')
                         reset()
                         setbulkUploadData({})
                      return 
                 }
                 else {
                  ShowNotification('Bulk Send ,Batch Not Created!','danger')
                    return
                 }
              })
              .catch((err)=>{
                ShowNotification("Something went wrong!",'danger')
              return
              })
             }
             else{
              ShowNotification(res.data.message,'danger')
      return
             }
         })
         .catch((err)=>{
          ShowNotification(err,'danger')
              return
         })
      }

      const BulkSendModal = () => {
          let letterHead = noticeDetails[0]?.letterhead_configuration
          letterHead = JSON.parse(letterHead)
            let requestPayload = {...bulkUploadData, notice_id:noticeDetails[0]?.notice_id,
              notice_name:noticeDetails[0]?.notice_type,
              lhead:letterHead.id,
              created_by:userData?.id,
              token:userData?.token,
              ref_id_check:1,
              is_sig:1,
              total_row :bulksendIds?.length,
              inserted_row:bulksendIds?.length,
              pdfall_ids :bulksendIds,
              mnid:noticeDetails[0]?.notice_id,
              rangeFrom:filter?.filterFrom,
              rangeTo:filter?.filterTo,
              rangeExcept:filter?.filterExcept
            }
            if(bulkUploadData.batchName){
              bulksendApi(requestPayload)
            }
            else {
               ShowNotification('Batch Name is Mandatory','danger')
            }
            
      }
  return (
    <div>
    <Modal open={open} onClose={handleSubmit}>

      <Modal.Body>
          <Label for="batchName">
          Enter a Batch Name :
          </Label>
          <Input
            type="text"
            id="batchName"
            // disabled={bulkData?.batchName}
            placeholder="Enter Batch Name"
            value={bulkUploadData.batchName}
            onChange={e => {
              setbulkUploadData({ ...bulkUploadData, batchName: e.target.value })
            }}
          />
            <label>Schedule on ( Not Mandatory )</label>
              <DatePicker
                onChangeCalendarDate={e =>setbulkUploadData({...bulkUploadData, schedule_on: e}) }
                format="dd-MM-yyyy HH:mm"
                block
              />
         <Button className="my-3" color="primary" onClick={()=>BulkSendModal()} >Bulk Send</Button>
      </Modal.Body>
    </Modal>
    </div>
  )
}

